// add more variables
$color-purple-light: lighten($purple, 20%);
$color-purple-lighter: lighten($purple, 35%);

@import "components/buttons";
@import "components/login";
@import "components/sidebar";
@import "components/toast";
@import "components/forms";
@import "components/colorpicker";
@import "components/print";
@import "components/utils";

@media (min-width: 2250px) {
  .main-container {
    max-width: 1950px;
    margin: 0 auto !important;
  }
}

.topbar-btn:hover,
.topbar .dropdown.show .topbar-btn {
  border-top-color: $green;
}
.spinner-linear .line {
  background-color: $green;
}

.sidebar-header > .logo-icon {
  display: none;
}
.sidebar-folded .sidebar-header > * {
  display: none;
}
.sidebar-folded .sidebar-header > .logo-icon {
  display: block;
}
.sidebar-folded .sidebar:hover .sidebar-header > * {
  display: block;
}
.sidebar-folded .sidebar:hover .sidebar-header > .logo-icon {
  display: none;
}
.sidebar-header > * {
  transition: none;
}

/* Fade effect */
.sidebar-folded .sidebar-header > .logo {
  opacity: 0;
}
.sidebar-folded .sidebar:hover .sidebar-header > .logo {
  opacity: 1;
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.header {
  margin-bottom: 0;
}
a:hover,
a:focus {
  color: $color-purple;
}

// language variations
.i18n > *,
.after-i18n {
  display: none;
}

// ajax utility classes
.ajax-form,
.ajax-content {
  position: relative;
}
.ajax-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -10;
  opacity: 0;
  transition: 0.2s linear;
  background: rgba(255, 255, 255, 0.7);
}
.ajax-overlay:not(:empty) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.ajax > .ajax-overlay {
  opacity: 1;
  z-index: 501;
}
.ajax-content > .spinner-linear,
.ajax-form > .spinner-linear {
  display: none;
  position: absolute;
  z-index: 502;
}
.ajax > .spinner-linear {
  display: block;
}
.ajax-cards .card {
  transition: 0.4s linear;
}
.ajax-cards.ajax .card {
  opacity: 0;
}

// horizontal scroll on app nav tabs
#app-tabs {
  position: relative;
  padding: 0 75px;
  background-color: $white;
  box-shadow: 3px 0 5px rgba($black, .05);
  z-index: 3;
}
#app-nav-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: flex-end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
#app-nav-container > .scrollable > .ps-scrollbar-x-rail {
  top: 2px !important;
}
.select-image.scrollable.scrollable-x .ps-scrollbar-x {
  height: 15px;
}
#app-nav-container > .scrollable > .ps-scrollbar-y-rail {
  display: none !important;
}
#app-nav-container > .scrollable > .nav {
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
#app-tabs .nav {
  min-height: 56px;
  border-bottom: none;
}
#app-tabs .nav-link {
  height: 100%;
  border-bottom: none;
  border-top: 1px solid transparent;
}
#app-tabs .nav-link:hover {
  border-top-color: $color-purple-lighter;
}
#app-tabs .nav-link.active {
  background-color: rgba($color-pale-purple, .2);
  border-top-color: $color-purple-light;
}
#app-tabs .nav-item {
  position: relative;
  // show with jQuery
  display: none;
}
#app-tabs .nav-item > .close-tab {
  cursor: pointer;
  position: absolute;
  font-size: 10px;
  right: 6px;
  top: 6px;
  opacity: 0;
  transition: 0.2s linear;
}
#app-tabs .nav-item:hover > .close-tab {
  opacity: .35;
}
#app-tabs .nav-item > .close-tab:hover {
  opacity: .85;
}
#app-tabs > a {
  position: absolute;
  top: 0;
  padding: 17px 15px 0 15px;
  height: 100%;
  font-size: 20px;
  text-align: center;
  z-index: 1;
  background: $white;
}
a#new-tab {
  left: 20px;
  color: $color-purple;
}
a#close-current-tab {
  right: 20px;
  color: $color-text-disable;
}
#app-tabs > a:hover {
  color: $color-purple-light;
}

// SPA, ajax router
#router {
  position: relative;
}
#router > .loading {
  position: absolute;
  display: none;
  z-index: 4;
}
#route-content {
  min-height: calc(100vh - 120px);
}
#route-content > *,
#route-content > .app-current-tab > * {
  display: none;
}
#router,
#route-content,
#route-content > .app-current-tab {
  display: block;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.btn-avatar {
  width: 65px;
  height: 65px;
  position: relative;
}
.btn-avatar .badge {
  border: 2px solid $white;
  z-index: 2;
  display: none;
  position: absolute;
  top: 13px;
  left: 13px;
}
.btn-avatar.has-new .badge {
  display: block;
}

.flags-list img {
  height: 15px;
  margin-right: 10px;
}

#search-results {
  position: absolute;
  display: none;
  z-index: 9;
  margin-top: 1px;
}

// prevent window width change
/*
main.scrollable {
  max-height: 100vh;
}
main.scrollable > .ps-scrollbar-y-rail {
  z-index: 100;
}
main.scrollable > .main-content {
  padding-bottom: 60px;
}
*/
body {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
// fix modal window width bugs
.modal-open,
.modal-open .modal {
  padding-right: 0 !important;
}
.modal-open .topbar {
  padding-right: 18px;
}
.modal-body.scrollable {
  max-height: calc(100vh - 180px);
}

// card control links
.card-btn-slide:not(:empty) {
  font-family: $font-body;
  transform: unset;
  color: $color-primary;
  font-weight: 400;
  text-align: right;
}
.card-btn-slide:not(:empty).rotate-180 {
  opacity: .3;
}
.card-btn-slide:not(:empty)::before {
  display: none;
}

.dock-list,
.modal-open .dock-list {
  z-index: 1001;
}
#dock-chat {
  max-width: 75%;
}
.dock-header-inverse .dock-actions > * {
  opacity: 1;
}
.media-chat-default + .media-chat-default {
  padding-top: 0;
  margin-top: -10px;
}
.media-chat-default + .media-chat-default + .media-chat-default {
  padding-top: 0;
  margin-top: -10px;
}

.app-backdrop.backdrop-quickview {
  z-index: 1002;
}
.quickview {
  z-index: 1003;
}

// fix icon alignment
.topbar-btn.sidebar-toggler {
  padding-top: 4px;
  line-height: 60px;
  font-size: 33px;
  margin-right: 10px;
}

// large search bar
#topbar-search .topbar-left,
#topbar-search .lookup {
  width: 100%;
}
.lookup-circle input {
  background-color: rgba($color-pale-purple, .3);
}
#topbar-search .lookup-circle input:focus {
  background-color: rgba($color-pale-purple, .6);
  color: $color-purple;
}

// second topbar with action buttons
#topbar-action {
  display: none;
}
#topbar-action h4 {
  margin-bottom: 0;
  font-weight: 500;
  color: rgba($white, .75);
  white-space: nowrap;
}
#topbar-action-toggle {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  margin-right: 0 !important;
}
#topbar-action.h-auto #topbar-action-toggle i::before {
  content: "\e64b";
}
#topbar-action-body.hidden > div {
  display: none;
}
#topbar-action-body,
#topbar-action .topbar-left {
  height: 100%;
}
#topbar-action .topbar-right {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0 20px 0 80px;
  background: linear-gradient(to right, rgba($color-purple, .1), $color-purple 20%);
}
#topbar-action .btn {
  margin-right: 12px;
}
#action-done {
  padding: 21px;
  margin-right: -20px;
  display: none;
  line-height: 1;
  font-size: 19px;
  font-weight: 400;
  background: $color-success;
}
#action-done > i {
  margin-right: 5px;
}

// JSON code editor
.ace-editor,
.ace_editor {
  height: 500px;
}
.ace_text-input {
  position: absolute !important;
}
// HTML editor
.note-toolbar.panel-heading {
  position: relative !important;
  width: 100% !important;
}

// selectpicker
.bootstrap-select .dropdown-menu > .no-results {
  font-size: 0;
  line-height: 0;
  padding: 6px;
}
.bootstrap-select .dropdown-menu > .no-results > div {
  display: none;
}
*:not(.selectpicker-default) > .bootstrap-select .dropdown-menu.inner span > small {
  display: block;
  line-height: 1;
  padding-left: 0 !important;
}
*:not(.selectpicker-default) > .bootstrap-select .dropdown-menu.inner span > small::before {
  content: "/ "
}
.bootstrap-select .bs-ok-default {
  display: none !important;
}
/*
.bootstrap-select .bs-ok-default::after {
  width: .45em;
  height: .9em;
  border-width: 0 .16em .16em 0;
}
*/
.bootstrap-select.bs-container .dropdown-menu {
  z-index: 994;
}

.header-action .nav-link.active,
.header-action .nav-link:hover {
  border-bottom-color: $color-purple-light;
}
// fix nav link item margin
.nav:not(.nav-tabs) .nav-link > i:first-child {
  margin-right: 0;
}
.nav:not(.nav-tabs) .nav-link > i:first-child + * {
  margin-left: 4px;
}

// buttons for document edition
.header-action .edit-btn {
  display: none;
  border-top: none;
  border-left: none;
  border-right: none;
}
.header-action .edit-btn,
.header-action .edit-btn:hover {
  border-color: $color-yellow;
}

// document pagination
.pagination-arrows {
  margin-left: 50px;
  margin-right: auto;
  display: none;

  i {
    cursor: pointer;
    color: $color-purple;

    &:not(:last-child) {
      margin-right: 15px;
    }

    &.disabled {
      opacity: .4;
      cursor: default;
    }
  }
}

// document modification date and time info
.document-dates {
  display: none;
  margin-right: 30px;
  color: $color-text-light;
  background: rgba($color-yellow, .1);
  padding: 3px 10px;
  border-radius: 2px;

  @media (max-width: 870px) {
    display: none !important;
  }

  i {
    margin-right: 6px;
    color: $color-text-disable;

    &:not(:first-of-type) {
      margin-left: 12px;
    }
  }
}

// dropzone progress bar is confused
.dz-progress .dz-upload {
  max-width: 93% !important;
}

// block to select image replacing input file
.select-image {
  cursor: pointer;
  height: 120px;
  width: 100%;
  background: $color-bg-lightest;
  border: $card-internal-border;
  overflow: hidden;
  position: relative;
}
.select-image.select-only {
  cursor: default;
}
.select-image > div {
  width: 100%;
}
.images-list {
  z-index: 1;
  padding: 15px 10px;
  min-width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.images-list:empty {
  position: absolute;
}
.select-image p {
  padding-top: 15px;
  display: none;
  transition: 0.2s linear;
}
.images-list:empty + p {
  display: block;
  width: 100%;
  text-align: center;
}
.select-image span {
  height: 90px;
  position: relative;
  margin: 0 5px;
  border: 1px solid $color-bg-lightest;
}
.select-image span:hover,
.select-image span.selected {
  border-color: $color-text;
}
.select-image span.selected {
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 22px;
}
.select-image span.selected::after {
  content: "\f058";
  display: block;
  color: $color-success;
  position: absolute;
  top: 3px;
  left: 6px;
}
.select-image span > img {
  height: 88px;
  width: auto;
  max-width: 180px;
  min-width: 50px;
  cursor: move;
}
.select-image span:only-child > img {
  max-width: none;
}
.select-image span > * {
  transition: 0.1s linear;
}
.select-image span:hover > img {
  opacity: .3;
}
.select-image span > i {
  font-size: 18px;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 5px;
  right: 5px;
}
.select-image span:hover > i {
  opacity: 1;
}
.select-image.select-only span > img {
  cursor: pointer;
}

// fix for checkbox control
.custom-control {
  &-inline {
    display: inline-flex;
  }

  &-label {
    cursor: pointer;
  }
}

// resources list
.data-list,
.data-list > * {
  width: 100% !important;
  height: auto !important;
  overflow: hidden !important;
}
@media (max-width: 1199px) {
  .data-list > * {
    min-width: 1080px !important;
  }
  .data-list {
    overflow-x: auto !important;
  }
}
.data-list table,
.data-list tbody {
  display: block;
  table-layout: unset;
}
.data-list tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.data-list tr > * {
  -ms-flex-positive: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  // width: 50px !important;
  border-left: none !important;
  border-bottom: none !important;
  box-sizing: border-box;
  overflow: hidden;
  list-style: none;
}
.data-list tr > .data-list-check,
.data-list tr > .data-list-fixed {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.data-list tr > td.data-list-multi {
  line-height: 1.25;
  a {
    border: none;
  }
}
.data-list tr > .data-list-check {
  width: 70px !important;
}
.data-list td > .data-list-control {
  position: relative;
}
.data-list td > .data-list-control .loading {
  margin: 0 auto;
  display: none;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  left: 50%;
  margin: -4px 0 0 -20px;
  background: #fcfcfc !important;
}
.data-list td > .spinner-circle-shadow {
  margin-top: 3px;
}
.data-list td > .bootstrap-select {
  width: 100% !important;
}
.data-list td > .bootstrap-select .btn,
.data-list td input {
  font-size: 13px;
  line-height: 20px;
  padding: 5px 7px;
}
.data-list td input + input,
.data-list td div + input {
  display: block;
  margin-top: 6px;
}
.data-list-control-buttons {
  text-align: center;
}
.data-list-control i {
  cursor: pointer;
  font-size: 18px;
  margin: 7px 7px 0 0;
  color: $color-text-secondary;
  transition: 0.2s linear;
}
.data-list-control i:hover {
  color: $color-primary;
}
.data-list-control-buttons i:last-child {
  margin-right: 0;
}
.data-list-check .custom-controls-stacked {
  width: 18px;
  display: block;
  margin: 0 auto;
}
.data-list-check .custom-controls-stacked .custom-control {
  margin-bottom: 0;
}
.data-list-accordion {
  display: none;
  width: 100%;
  padding: .7rem;
  border-top: 1px solid $color-purple-light;
  background: rgba($color-pale-purple, .2);
  font-size: 15px;
  line-height: 1.8;

  a {
    white-space: nowrap;
    overflow: hidden;
    display: inline-flex;
    max-width: 500px;

    &[href=" - "],
    &:empty {
      display: none;
    }
  }
}
.data-list-accordion span {
  display: inline-block;
  margin-right: 18px;
}
.data-list-accordion span:last-child {
  margin-right: 0;
}
.data-list-extend {
  position: relative;
}
.data-list-extend .custom-controls-stacked {
  margin: 0;
}
.data-list-extend > i {
  position: absolute;
  top: -6px;
  right: -2px;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 5px;
  color: $color-purple;
  transition: 0.2s linear;
}
.data-list-extend > i:hover {
  color: $color-primary;
}
.list-pagination *:disabled {
  opacity: .25;
  cursor: not-allowed;
  background: none;
}

// grids options ul
.options-list,
.badges-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.options-list > li {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 1rem;
  display: none;
}
.options-list > li:last-child {
  margin-bottom: 0;
}
.input-group-text > .custom-checkbox {
  width: 18px;
  margin-right: -6px;
}
.input-group-text > .custom-checkbox > label::after {
  top: 3px;
}
.options-list-striped li {
  border: $card-internal-border;
  padding: 0.6rem;
  margin-bottom: 0;
}
.options-list-striped li:not(:last-child) {
  border-bottom: none;
}
.options-list-striped li:nth-child(2n+1) {
  background: $color-bg-lightest;
}
.badges-list {
  margin-top: 5px;
  text-align: right;
}
.badges-list li {
  background: $color-primary;
  display: inline-block;
  margin: 5px 0 0 5px;
  border-radius: 2px;
  padding: 6px 20px;
  line-height: 1;
  font-size: 14px;
  font-weight: 400;
  color: $white;
  position: relative;
}
.badges-list li > .i-drag {
  position: absolute;
  left: 5px;
  top: 5px;
}
.badges-list li > .fa-times {
  position: absolute;
  right: 3px;
  top: 3px;
  padding: 2px;
  font-size: 12px;
  cursor: pointer;
}
ul > li:nth-child(4n+1) > .badges-list li {
  background: $color-cyan;
}
ul > li:nth-child(4n+2) > .badges-list li {
  background: $color-brown;
}
ul > li:nth-child(4n+3) > .badges-list li {
  background: $color-teal;
}
ul > li:nth-child(4n+4) > .badges-list li {
  background: $color-pink;
}
.colored > span {
  margin-right: 8px;
}
.colored > span:nth-child(4n+1) {
  color: $color-cyan;
}
.colored > span:nth-child(4n+2) {
  color: $color-brown;
}
.colored > span:nth-child(4n+3) {
  color: $color-teal;
}
.colored > span:nth-child(4n+4) {
  color: $color-pink;
}

// products list
.row-products > .col {
  -webkit-flex: 0 0 min(50%, 200px);
  -ms-flex: 0 0 min(50%, 200px);
  flex: 0 0 min(50%, 200px);
  margin-top: 10px;
  margin-bottom: 10px;
}
@media (max-width: 400px) {
  .row-products > .col {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
}
.row-products .item-info {
  line-height: 1.4;
  margin-bottom: 5px;
}
.row-products .item-name {
  height: 37px;
  display: block;
  overflow: hidden;
  width: 100%;
}
.row-products .item-price {
  font-weight: 500;
}
.row-products .item-picture,
.cart-item-picture {
  height: 102px;
  width: 102px;
  display: block;
  margin-bottom: 10px;
  background: $color-bg-lightest;
  border-radius: 2px;
  border: $card-internal-border;
  position: relative;
  overflow: hidden;
}
.cart-item-picture {
  height: 36px;
  width: 60px;
  margin-bottom: 0;
}
.row-products .item-picture img,
.cart-item-picture img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
}
.row-products .item-sku {
  display: inline-block;
  font-size: 90%;
  font-family: $font-family-monospace;
  line-height: 1.35;
}

// cart items table
.cart-items-list {
  overflow-x: auto;
  width: 100%;
}
.cart-items-list > table {
  min-width: 800px;
  max-width: none;
}
.cart-items-list:last-child > table {
  margin-bottom: 0;
}

.masonry-item > a {
  display: inline-block;
  border: $card-internal-border;
  padding: 3px;
  border-radius: 2px;
  transition: 0.1s linear;
}
.masonry-item > a:hover {
  border-color: $gray-500;
}
.masonry-item > a.active {
  border-color: $gray-700;
}
.masonry-item > a > img,
.masonry-item > img {
  width: 100%;
  border-radius: 2px;
}

.dataTables_scroll {
  overflow-x: auto;
}

// storage items async load
.storage-object {
  display: none;
}

// generic remove icon
.remove {
  color: $color-red;
  cursor: pointer;
  transition: 0.2s linear;
}
.remove:hover {
  color: $color-dark;
}
.nested-block {
  position: relative;
}
.nested-block .remove {
  position: absolute;
  top: -10px;
  right: -10px;
}

@media (max-width: 400px) {
  .masonry-grid {
    column-count: 2;
  }
}

@media (max-width: 500px) {
  .topbar {
    padding-right: 6px;
  }
  .topbar-btn {
    padding: 0 4px;
    min-width: 34px;
  }

  .topbar-divider {
    display: none;
  }

  .pagination-arrows {
    margin-right: 0;
    margin-left: auto;
  }
}

@media (max-width: 600px) {
  .breadcrumb {
    display: none;
  }
}

@media (min-width: 700px) {
  .masonry-grid {
    column-count: 4;
  }
}

#dashboard > .dashboard-start {
  opacity: 0;
}

// utilities
.fs-200 {
  font-size: 200px;
}
.bold {
  font-weight: 500;
}
.h-auto {
  height: auto;
}
.hidden {
  display: none;
}
.prev-empty,
.hide-empty:empty {
  display: none;
  transition: 0.2s linear;
}
*:empty + .prev-empty {
  display: inherit;
}
.inline-childs > * {
  display: inline-block;
  margin-right: 20px;
}
.inline-childs > *:last-child {
  margin-right: none;
}
.table-center td,
.table-center th {
  vertical-align: middle;
}
.pagination {
  flex-wrap: wrap;
  row-gap: 10px;
}
@media (max-width: 543px) {
  .hidden-xs-down {
    display: none;
  }
}
@media (max-width: 767px) {
  .hidden-sm-down {
    display: none;
  }
  .px-sm-40 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
}
@media (max-width: 991px) {
  .hidden-md-down {
    display: none;
  }
}
@media (min-width: 992px) {
  .hidden-lg-up {
    display: none;
  }
}
@media (min-width: 768px) {
  .hidden-md-up {
    display: none;
  }
}

// drag and drop icon
.i-drag {
  display: inline-block;
  width: 8px;
  height: 16px;
  cursor: move;
}
.i-drag,
.i-drag::before {
  background-image: radial-gradient($color-text 40%, transparent 40%);
  background-size: 4px 4px;
  background-position: 80% 0;
  background-repeat: repeat-y;
  text-align: left;
}
.i-drag.white,
.i-drag.white::before {
  background-image: radial-gradient($white 40%, transparent 40%);
}
.i-drag::before {
  content: '';
  display: inline-block;
  width: 42%;
  height: 100%;
}

.invoices {
  .table-responsive {
    tr > td {
      vertical-align: middle;
    }
    .img-product {
      display: none;
    }
  }
  .tax-extra {
    display: none;
  }
}
.report-table {
  width: 100%;
  @media(max-width: 767px) {
    max-height: 400px;
    overflow-y: auto;
  }
}
.tiny-table {
  display: block;
  max-height: 350px;
  overflow: auto;
}
