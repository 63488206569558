.text-whatsapp {
  color: #25d366 !important;
}
.text-telegram {
  color: #0088cc !important;
}

div:has(> [data-app-id="119392"]) {
  display: none;
}
