label {
  font-size: 13px;
}

.form-control {
  height: auto;

  &:disabled,
  &[readonly] {
    background: $color-bg-lighter;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.needs-validation {
  .form-control:invalid {
    border-color: $danger;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  }

  textarea.form-control:invalid {
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem);
  }
}

.switch input:checked ~ .switch-indicator {
  background: rgba($primary, .25);
}

.form-control,
.bootstrap-select .dropdown-toggle,
.jsgrid input {
  color: $gray-600;
  border-color: $gray-200;
  background: $white;
}

.input-group,
.form-control {
  max-width: 100%;
}

.input-group > .twitter-typeahead {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;

  > .form-control {
    z-index: 3;
  }
}

select,
.bootstrap-select {
  .dropdown-toggle {
    text-transform: none;

    &:not([disabled]):not(.disabled) {
      &:hover:not(:focus):not(:active):not([aria-expanded=true]) {
        background-color: lighten($light, 1%);
        box-shadow: none;
        color: $gray-900;
      }

      &:focus,
      &:active,
      &:active:focus,
      &[aria-expanded=true] {
        background-color: lighten($light, 1.15%);
        color: $gray-900;
        border-color: $primary;
        box-shadow: 0 0 0 .1rem rgba($primary, .15);
      }
    }
  }

  .dropdown-menu .selected {
    background-color: $light;

    a:after {
      position: absolute;
      top: .6rem;
      right: .7rem;
      content: "\f00c";
      color: $gray-800;
      font: normal normal normal 12px/1 FontAwesome;
    }
  }
}

.dropdown-item {
  &:hover {
    background-color: lighten($light, 1.3%);
  }

  &.active {
    background-color: $light;
  }
}
