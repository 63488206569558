button:focus {
  outline: none;
}

.btn {
  text-transform: uppercase;
  border-radius: 2px;
  font-weight: 500;
  font-size: .85rem;

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    opacity: .65;
  }

  &-lg {
    font-weight: 600;
  }
}

@each $color, $value in $theme-colors {
  $color-yiq-value: color-yiq($value);

  .btn-#{$color} {
    &:not(.btn-pure) {
      background-color: $value;
      border-color: $value;
      @if $color == 'light' {
        color: $gray-600;
      } @else {
        color: $color-yiq-value;
      }

      &:not([disabled]):not(.disabled) {
        &:hover,
        &:focus,
        &:active,
        &.active {
          background-color: darken($value, 3%);
          border-color: darken($value, 3%);
          @if $color == 'light' {
            color: $gray-700;
            box-shadow: 0 0 0 .1rem rgba($value, .8);
          } @else {
            color: $color-yiq-value;
            box-shadow: 0 0 0 .1rem rgba($value, .4);
          }
        }

        &:active:focus {
          border-color: darken($value, 5%);
          background-color: darken($value, 5%);
          @if $color == 'light' {
            color: $gray-700;
            box-shadow: 0 0 0 .1rem rgba($value, .9);
          } @else {
            color: $color-yiq-value;
            box-shadow: 0 0 0 .1rem rgba($value, .5);
          }
        }
      }
    }

    &.btn-pure {
      color: $value;

      &:not([disabled]):not(.disabled) {
        &:hover,
        &:focus,
        &:active,
        &.active {
          color: darken($value, 10%);
        }

        &:active:focus {
          color: darken($value, 15%);
        }
      }
    }
  }
}
